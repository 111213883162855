import styles from './thank-you.module.scss';
import thankYouSVG from '../../assets/images/thank-you-image.svg';
import {useTranslation} from "react-i18next";

const ThankYou = ({catalogUrl}) => {
    const {t} = useTranslation();

    return(
        <div className={styles.thankYouWrapper}>
            <div className={styles.thankYouWrapper__content}>
                <img src={thankYouSVG} alt="thank-you"/>
                <span>{t('success')}</span>
                <h1>{t('success-title')}</h1>
                <p>{t('success-subtitle')}</p>
                <a href={window.location.pathname.startsWith('/th') ? catalogUrl?.th : catalogUrl?.en}>{t('back-to-catalog-button')}</a>
            </div>
        </div>
    )
}

export default ThankYou;
