import styles from './header.module.scss';
import LogoSVG from '../../assets/images/logo.svg';
import Language from '../language-dropdown';

const Header = () =>{
    return(
        <header className={styles.header}>
            <img src={LogoSVG} alt="logo"/>
            <Language/>
        </header>
    )
}

export default Header;
