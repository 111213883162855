import styles from './language.module.scss';
import {useEffect, useRef, useState} from "react";
import ArrowSVG from '../../assets/images/arrow-down-icon.svg';
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const LANGUAGES = {
    EN: 'EN',
    TH: 'TH'
}

const Language = () =>{

    const [languages, setLanguages] = useState(LANGUAGES.EN);
    const [showLanguagesList, setShowLanguagesList] = useState(false);
    const btnRef = useRef(null);
    const listRef = useRef(null);

    useEffect(()=>{
        setLanguages(localStorage.getItem('i18nextLng').toUpperCase());
    },[languages])

    const changeLanguage = ({target: { innerText }}) => {
        const lg = innerText.toLowerCase();
        setLanguages(LANGUAGES[innerText]);
        setShowLanguagesList(false);
        i18n.changeLanguage(lg);
        window.history.pushState({urlPath: `${ lg }`},'',`${ lg }`);
    }

    const {i18n} = useTranslation();

    const languagesListClick = () => setShowLanguagesList(!showLanguagesList);

    const handleClickOutside = ({ target }) => {
        if (listRef.current && !listRef.current.contains(target) && !btnRef?.current.contains(target)) {
            setShowLanguagesList(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return(
        <div className={styles.languageDropdown}>

            <button onClick={languagesListClick} ref={btnRef}>
                {languages}
                <img src={ArrowSVG} alt="arrow" className={classNames(styles.arrow, {
                    [styles.arrow_active] : showLanguagesList
                })}/>
            </button>

            {
                showLanguagesList &&
                <ul ref={listRef}>
                    {
                        Object.keys(LANGUAGES).map(key =>
                            <li
                                className={classNames(styles.listItem, {
                                [styles.listItem_active]: languages === key
                                })}
                                key={key}
                            >
                                <button
                                    onClick={changeLanguage}
                                >
                                    {key}
                                </button>
                            </li>)
                    }
                </ul>
            }
        </div>
    )
}

export default Language;
