import * as yup from "yup";

const ERROR_NUMBER = 'must be a number';
const ERROR_REQUIRED_FIELD = 'required field';
const ERROR_VALID_EMAIL = 'email must be a valid email';
const ERROR_MUST_BE_INTEGER = 'must be a number';

export const validationsSchema = yup.object().shape({
    referrer_member_id: yup.string().matches(/(^(\d+\/\d+)$)|(^\d+$)/, ERROR_MUST_BE_INTEGER),
    referrer_name: yup.string().required(ERROR_REQUIRED_FIELD),
    referrer_address: yup.string().required(ERROR_REQUIRED_FIELD),
    referrer_mobile_no: yup.number().typeError(ERROR_NUMBER).required(ERROR_REQUIRED_FIELD),
    referrer_email: yup.string().email(ERROR_VALID_EMAIL).required(ERROR_REQUIRED_FIELD),

    member_id: yup.string().matches(/(^(\d+\/\d+)$)|(^\d+$)/, ERROR_MUST_BE_INTEGER),
    member_name: yup.string().required(ERROR_REQUIRED_FIELD),
    member_address: yup.string().required(ERROR_REQUIRED_FIELD),
    member_mobile_no: yup.number().typeError(ERROR_NUMBER).required(ERROR_REQUIRED_FIELD),
    member_email: yup.string().email(ERROR_VALID_EMAIL).required(ERROR_REQUIRED_FIELD),
});
