let th = {
    translations: {
        "title-refer-new-members": "แนะนำสมาชิกใหม่",
        "subtitle-refer-new-members": "รับบัตรของขวัญไปช็อปฟรีที่เซ็นทรัล!",
        "description": "เมื่อสมาชิกปัจจุบันแนะนำสมาชิกในครอบครัว เพื่อน หรือคนรู้จัก ให้มาเป็นสมาชิกน้ำดื่มสปริงเคิล สมาชิกผู้แนะนำจะได้รับบัตรของขวัญในการแลกซื้อสินค้าในห้างเซ็นทรัลทุกสาขา",
        "sub-description": "บริษัทฯจะจัดส่งบัตรกำนัลให้กับสมาชิกผู้แนะนำหลังจากสมาชิกใหม่ซื้อคูปองและรับบริการครบระยะเวลาตามเงื่อนไขที่บริษัทฯกำหนด",
        "gift-title": "บัตรของขวัญมูลค่า",
        "gift-description1": "เมื่อแนะนำสมาชิกใหม่ 1 ท่าน ภายใน 1 เดือน",
        "gift-description2": "เมื่อแนะนำสมาชิกใหม่ 2 ท่าน ภายใน 1 เดือน",
        "gift-description3": "เมื่อแนะนำสมาชิกใหม่ 6 ท่าน ภายใน 6 เดือน",
        "bonus": "รับบัตรของขวัญมูลค่า 3,000 บาทเพิ่มเมื่อแนะนำสมาชิกใหม่ 6 ท่าน ภายใน 6 เดือน โดยนับเวลาต่อเนื่องกันตั้งแต่เดือนแรกที่สมาชิกรายแรกที่ได้รับการแนะนำรับน้ำดื่ม",
        "conditions-title": "เงื่อนไข",
        "conditions-subtitle": "ในการรับบัตรของขวัญ",
        "first-point": "สมาชิกใหม่ชำระค่ามัดจำถัง",
        "second-point": "สมาชิกใหม่ชำระค่าคูปองแลกรับน้ำดื่มสปริงเคิล 24 ใบ",
        "third-point": "สมาชิกใหม่รับน้ำดื่มสปริงเคิล และสั่งซื้อคูปองแลกรับน้ำดื่ม 24 ใบ ชุดที่ 2 ",
        "fourth-point": "สมาชิกใหม่รับน้ำดื่มอย่างต่อเนื่อง และสั่งซื้อคูปองแลกรับน้ำดื่มสปริงเคิลอีก 1 ชุด หลังจากได้รับการแนะนำ",
        "notes-title": "หมายเหตุ",
        "first-note": "คูปอง 12 ใบ/ชุด ไม่เข้าร่วมรายการ",
        "second-note": "ที่อยู่ของสมาชิกใหม่ต้องไม่ซ้ำกับที่อยู่ของสมาชิกปัจจุบัน",
        "third-note": "หลังจากแนะนำสมาชิกใหม่ สมาชิกปัจจุบันต้องซื้อคูปองเพื่อรับบริการน้ำดื่มอย่างต่อเนื่องอย่างน้อย 1 ชุด",
        "fourth-note": "ผู้ได้รับบัตรของขวัญมูลค่า 1,000 บาทขึ้นไป ต้องเสียภาษี ณ ที่จ่าย 5%",
        "remark": "ผู้ได้รับบัตรของขวัญมูลค่าตั้งแต่ 1,000 บาทขึ้นไป ต้องเสียภาษีหัก ณ ที่จ่าย 5%",
        "recommender-form-title": "สมาชิกผู้แนะนำ",
        "member-form-title": "สมาชิกใหม่",
        "membership-no": "รหัสสมาชิก",
        "name": "ชื่อ/บริษัท",
        "address": "ที่อยู่",
        "phone": "เบอร์โทรศัพท์",
        "fax": "แฟ็กซ์",
        "email": "อีเมล",
        "confirm-button": "ยืนยันการสมัครสมาชิก",
        "success": "สำเร็จ",
        "success-title": "บริษัทได้รับข้อมูลจากท่านแล้ว",
        "success-subtitle": "ท่านจะได้รับการติดต่อกลับเพื่อยืนยันภายใน 7 วันทำการ",
        "back-to-catalog-button": "กลับไปหน้ารายการสินค้า",
    }
};

export default th;
