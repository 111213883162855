import styles from './styles/app.module.scss';
import Header from './components/header';
import ReferNewMember from './components/refer-new-members';
import Form from './components/form';
import ThankYou from "./components/thank-you";
import {useState} from "react";

localStorage.setItem('i18nextLng', window.location.pathname.startsWith('/th') ? 'th' : 'en');

function App() {

    const [showThankYou, setShowThankYou] = useState(false);
    const [catalogUrl, setCatalogUrl] = useState({});

    return (
        <main className={styles.mainContainer}>
            <Header/>
            {
                !showThankYou ? (
                    <div className={styles.mainContainer__content}>
                        <ReferNewMember/>
                        <Form setShowThankYou={setShowThankYou} setCatalogUrl={setCatalogUrl}/>
                    </div>
                ) : <ThankYou catalogUrl={catalogUrl}/>
            }
        </main>
    );
}

export default App;
