let en = {
    translations: {
        "title-refer-new-members": "Refer New Members",
        "subtitle-refer-new-members": "And Get a Shopping Spree!",
        "description": "When a current member refers his/her family and friends to apply for a Sprinkle membership, the referrer will get rewarded with Central gift vouchers shopping at all Central Department Store branches.",
        "sub-description": "A referrer will receive a Central gift voucher only when a referee buys the coupon and receives the service per the conditions.",
        "gift-title": "gift voucher for",
        "gift-description1": "1 new member within a month",
        "gift-description2": "2 new members within a month",
        "gift-description3": "6 new members within 6 month",
        "bonus": "Earn an additional 3,000 THB bonus by referring 6 new members within 6 months, counted consecutively from the first month the first referred member has the first water delivery.",
        "conditions-title": "Conditions",
        "conditions-subtitle": "For receiving a gift card",
        "first-point": "The referee has paid for his/her bottle deposit",
        "second-point": "The referee has paid for 24 Sprinkle coupons",
        "third-point": "The referee continues the subscription and orders for set 2 of 24 Sprinkle coupons",
        "fourth-point": "The referrer still receiving the same average drinking water service and purchasing at least 1 set of Sprinkle coupons after the referral.",
        "notes-title": "Please note that",
        "first-note": "A set of 12 Sprinkle coupons is excluded.",
        "second-note": "An address of a referee must not be the same as the current member's address.",
        "third-note": "A referrer msut maintain the subscription rate by purchasing at least a set of 24 Sprinkle coupons after the referral.",
        "fourth-note": "Gift vouchers of more than 1,000 baht value are subjected to a 5% withholding tax.",
        "remark": "*Gift Vouchers of more than 1,000 baht value are subject to 5% Withholding Tax",
        "recommender-form-title": "Recommender Info",
        "member-form-title": "Member Info",
        "membership-no": "Member ID",
        "name": "Name/Company",
        "address": "Address",
        "phone": "Phone",
        "fax": "Fax",
        "email": "E-mail",
        "confirm-button": "confirm to apply",
        "success": "Success",
        "success-title": "We have successfully received your information.",
        "success-subtitle": "We will confirm back within 7 working days.",
        "back-to-catalog-button": "Back to catalog",
    }
};

export default en;
