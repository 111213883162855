import styles from './form.module.scss';
import {Formik} from "formik";
import classNames from "classnames";
import {validationsSchema} from "./validationsSchema";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {useEffect, useRef} from "react";

const values = {
    recaptcha: null,
    referrer_member_id: '',
    referrer_name: '',
    referrer_address: '',
    referrer_mobile_no: '',
    referrer_email: '',

    member_id: '',
    member_name: '',
    member_address: '',
    member_mobile_no: '',
    member_email: '',
}

const ACTION_URL = '/api/submit';
const SITE_KEY = '6LfBUlwgAAAAAAEDtYIRXWPEj2WWk5K6G2LwC3rC'

const Form = ({setShowThankYou, setCatalogUrl}) => {

    const INTERVAL = 120000;
    const recaptchaRef = useRef(null);
    const {t} = useTranslation();

    useEffect(()=>{
        recaptchaRef.current.execute();
        setInterval(()=>{
            recaptchaRef.current.execute();
        }, INTERVAL);
    },[])

    const inputs = [
        { id: 'referrer_member_id', label: t('membership-no'), type: 'text', requiredField: false },
        { id: 'referrer_name', label: t('name'), type: 'text', requiredField: true },
        { id: 'referrer_address', label: t('address'), type: 'text', requiredField: true },
        { id: 'referrer_mobile_no', label: t('phone'), type: 'tel', requiredField: true },
        { id: 'referrer_email', label: t('email'), type: 'email', requiredField: true },
        { title: t('member-form-title')},
        { id: 'member_id', label: t('membership-no'), type: 'text', requiredField: false },
        { id: 'member_name', label: t('name'), type: 'text', requiredField: true },
        { id: 'member_address', label: t('address'), type: 'text', requiredField: true },
        { id: 'member_mobile_no', label: t('phone'), type: 'tel', requiredField: true },
        { id: 'member_email', label: t('email'), type: 'email', requiredField: true },
    ];

    const sendValues = async (values) => {
        try{
            if(values.recaptcha){
                const { status, data: { success, catalog_en, catalog_th }  } = await axios.post(ACTION_URL, {...values});
                setCatalogUrl({en: catalog_en, th: catalog_th});
                status === 200 && success && setShowThankYou(true);
            }
        }catch ({message}){
            console.error(message);
        }
    }

    const handleChangeCaptcha = (value, isSubmitting, setFieldValue, setSubmitting) => {
        setFieldValue('recaptcha', value);
        setSubmitting(false);
    };

    return(
        <div className={styles.formsWrapper}>
            <h1>{t('recommender-form-title')}</h1>

            <Formik
                initialValues={values}
                validateOnBlur
                onSubmit={sendValues}
                validationSchema={validationsSchema}
            >
                {
                    (props)=> {

                        const {values, errors, touched, handleChange, isValid, handleSubmit, dirty, isSubmitting, setSubmitting, setFieldValue } = props;

                        return (
                            <form action={ACTION_URL} className={styles.form}>
                                {
                                    inputs.map((input) => {
                                        return (
                                            <>
                                                {input.title ? <h1>{input.title}</h1> : (
                                                    <div className={styles.inputBlock} key={input.id}>
                                                        <label htmlFor={input.id}>{input.label}<span>{input.requiredField && ' *'}</span></label>
                                                        <input
                                                            id={input.id}
                                                            name={input.id}
                                                            type={input.type}
                                                            onChange={handleChange}
                                                            value={values[input.id]}
                                                        />
                                                        {touched[input.id] && errors[input.id] && <p className={styles.error}>{errors[input.id]}</p>}
                                                    </div>
                                                )}
                                            </>
                                        )
                                    })
                                }

                                <button
                                    className={classNames(styles.confirmButton, {
                                        [styles.confirmButton_active]: isValid && dirty
                                    })}
                                    disabled={!isValid && !dirty}
                                    type={'button'}
                                    onClick={handleSubmit}
                                >
                                    {t('confirm-button')}
                                </button>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={SITE_KEY}
                                    size="invisible"
                                    onChange={(value) => handleChangeCaptcha(value, isSubmitting, setFieldValue, setSubmitting)}
                                />
                            </form>
                        );
                    }
                }
            </Formik>

        </div>
    )
}

export default Form;
