import styles from './referNewMembers.module.scss';
import {useTranslation} from "react-i18next";

const ReferNewMember = () => {

    const {t} = useTranslation();

    const gifts = [
        { price: '฿500', title: t('gift-title'), description: t('gift-description1')},
        { price: '฿2,000', title: t('gift-title'), description: t('gift-description2')},
        { price: '฿3,000', title: t('gift-title'), description: t('gift-description3')}]

    return(
        <div className={styles.referNewMembers}>
            <h1>{t('title-refer-new-members')}</h1>
            <span>{t('subtitle-refer-new-members')}</span>
            <p>{t('description')}</p>
            <p>{t('sub-description')}</p>

            <div className={styles.giftsList}>
                {
                    gifts.map(item=>{
                        return (
                            <div className={styles.giftsList__item} key={item.price}>
                                <div className={styles.giftsList__title}>
                                    <h4>{item.price}</h4>
                                    <span>{item.title}</span>
                                </div>
                                <p>{item.description}</p>
                            </div>
                        )
                    })
                }
            </div>

            <p>{t('bonus')}</p>

            <div className={styles.conditions}>
                <h4>{t('conditions-title')}</h4>
                <p>{t('conditions-subtitle')}</p>
                <ul className={styles.conditions_points}>
                    <li>{t('first-point')}</li>
                    <li>{t('second-point')}</li>
                    <li>{t('third-point')}</li>
                    <li>{t('fourth-point')}</li>
                </ul>
                &nbsp;
                <p>{t('notes-title')}</p>
                <ol className={styles.conditions_notes}>
                    <li>{t('first-note')}</li>
                    <li>{t('second-note')}</li>
                    <li>{t('third-note')}</li>
                    <li>{t('fourth-note')}</li>
                </ol>
            </div>


        </div>
    )
}

export default ReferNewMember;
